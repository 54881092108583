
import { defineComponent } from "vue";

type action = {
  key: string;
  sound: string;
  predicate: (status: boolean) => boolean;
  action: (status: boolean) => boolean;
};

export default defineComponent({
  name: "App",
  data() {
    return {
      actions: [
        {
          key: "enter",
          sound: require("./assets/sounds/shift.mp3"),
          predicate: (status: boolean) => !status,
          action: (status: boolean) => !status,
        },
        {
          key: "backspace",
          sound: require("./assets/sounds/off.mp3"),
          predicate: (status: boolean) => status,
          action: (status: boolean) => !status,
        },
        {
          key: "1",
          sound: require("./assets/sounds/beep1.mp3"),
          predicate: (status: boolean) => status,
          action: (status: boolean) => status,
        },
        {
          key: "2",
          sound: require("./assets/sounds/beep2.mp3"),
          predicate: (status: boolean) => status,
          action: (status: boolean) => status,
        },
      ],
      status: false,
    };
  },
  computed: {
    instructions() {
      if (this.status) {
        return "Use '1' and '2' to beep and 'backspace' to turn sticky keys off.";
      } else {
        return `Press 'enter' to turn on sticky keys.`;
      }
    },
  },
  methods: {
    play({ key, repeat }: { key: string; repeat: boolean }): void {
      if (repeat) return;

      key = key.toLowerCase();

      const { sound, predicate, action } =
        this.actions.filter((action: action) => action.key === key)[0] || {};

      if (sound && predicate(this.status)) {
        const audio = new Audio(sound);
        audio.play();
        this.status = action(this.status);
      }
    },
    toggle() {
      const key = this.status ? "backspace" : "enter";
      return this.play({ key, repeat: false });
    },
    beep1() {
      this.play({ key: "1", repeat: false });
    },
    beep2() {
      this.play({ key: "2", repeat: false });
    },
  },
});
